<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" class="my-1">
    </b-col>
    <b-col md="4" class="my-1">
      <b-button variant="info" block size="sm" v-b-modal.modal-1 @click="tambah">
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Pengajuan Peminjaman
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <div>
            <b-row class="match-height">
              <b-col md="6">
                <b-alert variant="" show>
                  <div class="alert-body">
                    <span class="text-primary">Mitra UMKM : {{ data.item.nama_umkm }}</span><br>
                    <span class="text-secondary">Jenis Usaha {{ data.item.jenis_usaha }}</span><br>
                    <small>No Hp : {{ data.item.no_hp }}</small>

                  </div>
                </b-alert>

              </b-col>
              <b-col md="6">
                <b-alert variant="success" show style="" v-show="data.item.status_peminjaman == 'acc'">
                  <div class="alert-body">
                    <h6><b-badge variant="primary">Pengajuan Tervalidasi</b-badge> </h6>
                    <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                        Kode Alat {{ data.item.kode_alat }}
                        {{ data.item.id }}</strong></span><br>
                    <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                  </div>
                </b-alert>

                <b-alert variant="warning" show style="" v-show="data.item.status_peminjaman == 'pengajuan'">
                  <div class="alert-body">
                    <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                        Kode Alat {{ data.item.kode_alat }}
                        {{ data.item.id }}</strong></span><br>
                    <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                  </div>
                </b-alert>

                <b-alert variant="danger" show style="" v-show="data.item.status_peminjaman == 'ditolak'">
                  <div class="alert-body">
                    <h6><b-badge variant="danger">Pengajuan Ditolak</b-badge> </h6>
                    <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                        Kode Alat {{ data.item.kode_alat }}
                        {{ data.item.id }}</strong></span><br>
                    <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                  </div>
                </b-alert>

                <b-alert variant="primary" show style="" v-show="data.item.status_peminjaman == 'pengembalian'">
                  <div class="alert-body">
                    <h6><b-badge variant="primary">Alat Produksi Telah dikembalikan</b-badge> </h6>
                    <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                        Kode Alat {{ data.item.kode_alat }}
                        {{ data.item.id }}</strong></span><br>
                    <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                  </div>
                </b-alert>
                <div v-if="data.item.status_peminjaman == 'pengajuan'">
                  <!-- <b-badge variant="primary" class="mt-1 d-block">
                    <feather-icon icon="EditIcon" size='15' /> Edit Pengajuan
                  </b-badge> -->

                  <b-badge variant="danger" class="mt-1 d-block" @click="HapusData(data.item.id)">
                    <feather-icon icon="TrashIcon" size='15' /> Hapus Pengajuan
                  </b-badge>
                </div>
              </b-col>

            </b-row>
          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],


    }
  },
  props: {
    rs_data: {},
    keterangan: {},
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ['tambah', 'HapusData'],
  methods: {
    HapusData(id) {
      this.$emit('HapusData', id)
    },
    tambah() {
      this.$emit('tambah')
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>