<template>
  <div>
    <b-modal id="modal-1" title="Pengajuan Peminjaman Alat Produksi" ok-disabled no-close-on-backdrop size="lg"
      hide-footer>
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group label="Alat yang akan dipinjam">
              <validation-provider #default="{ errors }" rules="required" name="Item Alat yang akan dipinjam">
                <v-select v-model="inputs.id_item_alat_prodiksi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="CekData(input.id_item_alat_prodiksi, input.tgl_mulai)" label="kode_item" :options="alat" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tgl Mulai Peminjaman" label-for="Tanggal Mulai Peminjaman">
              <validation-provider #default="{ errors }" rules="required" name="Tanggal Mulai Peminjaman">
                <flat-pickr v-model="inputs.tgl_mulai" :state="errors.length > 0 ? false : null"
                  @input="CekData(input.id_item_alat_prodiksi, input.tgl_mulai)" :config="{ dateFormat: 'Y-m-d' }"
                  class="form-control" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Tgl Mulai Selesai" label-for="Tanggal Mulai Selesai">
              <validation-provider #default="{ errors }" rules="required" name="Tanggal Mulai Selesai">
                <flat-pickr v-model="inputs.tgl_selesai" :state="errors.length > 0 ? false : null"
                  :config="{ dateFormat: 'Y-m-d' }" class="form-control" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-show="bentrok.st_bentrok == '1' && inputs.id_item_alat_prodiksi.id_item_alat_prodiksi != '' && inputs.tgl_mulai != ''">
              <b-alert variant="danger" show>
                <div class="alert-body">
                  <span>Mohon maaf transaksi peminjaman tidak dapat di transaksikan, posisi alat sedang di pinjam mitra
                    UMKM
                    {{ bentrok.nama_umkm }} dari tanggal {{ bentrok.tgl_mulai }} sampai dengan {{ bentrok.tgl_selesai
                    }}</span>
                </div>
              </b-alert>
            </div>
            {{ input }}
            <div v-if="input.id_item_alat_prodiksi != '' && bentrok.st_bentrok != '1'">
              <b-col cols=" 12">
                <b-button variant="primary" type="submit" @click.prevent="validationForm">
                  <feather-icon icon="SaveIcon" size='15' /> Ajukan Peminjaman
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>

      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer,
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BForm, BFormInput, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BForm, BFormInput, BFormGroup,
    BAlert, ValidationProvider, ValidationObserver, vSelect, flatPickr
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required, integer,

    }
  },
  mixins: [Base],
  mounted() {


  },

  emits: ["CekData", "simpanData"],
  methods: {
    CekData(id_item_alat_prodiksi, tgl_mulai) {
      this.$emit("CekData", id_item_alat_prodiksi, tgl_mulai)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('simpanData', this.inputs)
          this.$root.$emit('bv::hide::modal', 'AddLevel')
          this.close();
          this.bentrok = {
            st_bentrok: 0
          }
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    alat: {},
    histori_alat: {},
    bentrok: {
      st_bentrok: 0
    },
    input: {}
  },
  computed: {
    inputs: {
      get: function () {
        return this.input;
      },
      set: function (newValue) {
        return this.input = newValue;
      }
    },
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>