<template>
  <div>
    <statistik :countData="countData" @actLoad="actLoad"></statistik>
    <b-card>

      <b-card-body>
        <h4><feather-icon icon="GridIcon" size='20' /> {{ keterangan }}</h4>
        <tabel-data :rs_data="rs_data" :keterangan="keterangan" @tambah="tambah" @HapusData="HapusData"></tabel-data>
      </b-card-body>
    </b-card>
    <tambah-pengajuan :alat="alat" @simpanData="simpanData" @CekData="CekData" :histori_alat="histori_alat"
      :bentrok=bentrok :input="input"></tambah-pengajuan>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import tabelData from './component/tabel.vue';
import statistik from './component/statistik.vue';
import TambahPengajuan from './component/tambahPengajuan.vue';
export default {
  mixins: [Base],
  data() {
    return {
      rs_data: {},
      status: '',
      countData: [],
      alat: [],
      tgl_mulai: "",
      keterangan: 'Semua Transaksi',
      id_item_alat_prodiksi: '',
      histori_alat: [],
      bentrok: {
        bentrok: '0'
      },
      input: {}
    }
  },
  components: {
    tabelData, statistik, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
    TambahPengajuan,
  },

  mounted() {
    this.get_lis_pengajuan();
    this.get_count_pengajuan();
    this.loadAlat();
  },
  methods: {
    async HapusData(id) {
      if (confirm('Apakah anda yakin akan mengapus data ini')) {
        const self = this;
        await axios({
          method: 'post',
          url: '/api/mitra/delete',
          data: {
            id: id
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.rs = response.data.result;
            self.loadAlat();
            self.get_lis_pengajuan();
            self.get_count_pengajuan();
            self.notification('danger', "Hapus Data", response.data.message);
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    async simpanData(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/mitra/insert_peminjaman_alat',
        data: {
          id_item_alat_prodiksi: input.id_item_alat_prodiksi.id_item_alat_prodiksi,
          tgl_mulai: input.tgl_mulai,
          tgl_selesai: input.tgl_selesai
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.loadAlat();
          self.get_lis_pengajuan();
          self.get_count_pengajuan();
          self.notification('info', "info", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    tambah() {
      this.bentrok = {
        bentrok: '0'
      };
      this.input = {
        id_item_alat_prodiksi: '',
        tgl_mulai: '',
        tgl_selesai: ''
      }
    },
    CekData(id_item_alat_produksi, tgl_mulai) {
      this.id_item_alat_prodiksi = id_item_alat_produksi.id_item_alat_prodiksi;
      this.tgl_mulai = tgl_mulai
      this.loadHistori();
      this.laod_penecekan_bentrok_peminjaman();

    },

    async loadHistori() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/mitra/histori_alat',
        data: {
          id_item_alat_prodiksi: self.id_item_alat_prodiksi,
          tgl_mulai: self.tgl_mulai
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.histori_alat = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async laod_penecekan_bentrok_peminjaman() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/mitra/penecekan_bentrok_peminjaman',
        data: {
          id_item_alat_prodiksi: self.id_item_alat_prodiksi,
          tgl_mulai: self.tgl_mulai
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.bentrok = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadAlat() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/mitra/get_alat',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.alat = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    actLoad(label, keterangan) {
      this.status = label;
      this.keterangan = keterangan
      this.get_lis_pengajuan();
    },
    async get_lis_pengajuan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/mitra/get_lis_pengajuan',
        data: {
          status: self.status
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async get_count_pengajuan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/mitra/count_pengajuan',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.countData = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }

}
</script>

<style></style>