<template>
  <b-card no-body class="card-statistics">
    <b-card-header style="margin-bottom: -25px;;">
      <b-card-title><feather-icon icon="GridIcon" size='20' /> Pengajuan Peminjaan Alat Produksi</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <a href="#">
            <b-media no-body @click="actLoad('pengajuan', 'Pengajuan Peminjaman')">
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="AirplayIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ countData['1'].jm_pengajuan }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pengajuan Peminjaman
                </b-card-text>
              </b-media-body>
            </b-media>
          </a>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <a href="#">
            <b-media no-body @click="actLoad('acc', 'Pengajuan tervalidasi')">
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="CheckSquareIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ countData['0'].jm_pengajuan }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pengajuan tervalidasi
                </b-card-text>
              </b-media-body>
            </b-media>
          </a>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <a href="#">
            <b-media no-body @click="actLoad('ditolak', 'Pengajuan Ditolak')">
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-danger">
                  <feather-icon size="24" icon="AlertTriangleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ countData['2'].jm_pengajuan }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pengajuan Ditolak
                </b-card-text>
              </b-media-body>
            </b-media>
          </a>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <a href="#">
            <b-media no-body @click="actLoad('pengembalian', 'Pengajuan Dikembalikan')">
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="ArchiveIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ countData['3'].jm_pengajuan }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pengajuan Dikembalikan
                </b-card-text>
              </b-media-body>
            </b-media>
          </a>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {

    }
  },
  props: {
    countData: {}
  },
  emits: ['actLoad'],
  methods: {
    actLoad(label, keterangan) {
      this.$emit('actLoad', label, keterangan);
    }
  }
}
</script>
